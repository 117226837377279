import React from 'react';

import Layout from '../layout';
import Dashboard from '../components/dashboard';
import 'antd/dist/antd.css';
import './style.css';

const IndexPage = props => {
  return <Dashboard />;
};

export default IndexPage;
