import React from 'react';
import './style.css';
import logo from '../../images/jcr-dark.png';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="textContainer">
        <h3>Materials Permanents per a la</h3>
        <h2>preservació i conservació</h2>
        <p>Materials de qualitat per a la preservació i conservació per l’arxiu de materials textuals,gràfics i fons fotogràfics.</p>
        <p>Fabricació pròpia i especials de grans, petites series.</p>
        <p>Formats standard i fabricació també a mida sota comanda.</p>
      </div>
    </div>
  );
};

export default Dashboard;
